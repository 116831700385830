import React, { useContext, useState } from 'react'
import { Table, Space, Tooltip, Skeleton, Empty } from 'antd'
import DeleteModal from '../../DeleteModal';
import { AppContext } from "../../../context/AppContextProvider";
import ClientContext from '../../../context/clientContext';
import DeactivateClient from "./DeactivateClient";
import { Link } from "react-router-dom";
import useAxiosInstance from '../../../lib/useAxiosInstance';

const Column = Table;

const ClientDataTable = ({ usersData, userSearchData, handleDelete, pagination, isLoading }) => {
    const axiosInstance = useAxiosInstance();
    const clientContext = useContext(ClientContext);
    const { isDropdownOpen, handleClick, handleMouseEnter, handleMouseLeave } = useContext(AppContext);

    const [isDeleteModal, setDeleteModal] = useState(false);
    const [selectedRecord, setSelectedRecord] = useState(null);

    const showModalDelete = (record) => {
        setSelectedRecord(record);
        setDeleteModal(!isDeleteModal)
    };


    const handleEdit = async (record, type) => {
        try {
            const response = await axiosInstance.get(`api/clients/${record._id}`);
            const clientData = response.data.data;
            clientContext.updateEditData(clientData);

            if (type === 'edit') {
                clientContext.updateAddMode(false);
                clientContext.updateOpenModal(true);
            } else if (type === 'status') {
                clientContext.updateStatusModal(true);
            }
        } catch (error) {
            // Handle error
            console.error('Error fetching client data:', error);
        }
    };

    return (
        <div className=''>
            <Table rowKey={record => record._id} dataSource={userSearchData || usersData.data} className="user-table-inner" pagination={pagination}
           locale={{
          emptyText: isLoading ? (
            <div
              style={{
                marginLeft: "20px",
                width: "95%"
              }}
            >
              {" "}
              {/* Container with 90% width */}
              <Skeleton
                title={false}
                active
                paragraph={{
                  rows: 6,
                  width: ["100%", "100%", "100%", "100%", "100%", "100%"]
                }}
              />
            </div>
          ) : (
            <Empty />
          )
        }}
 >
                <Column key="client_name" title="CLIENT NAME" dataIndex="name"
                    render={(text, record) => (
                        <>
                            {record.status === "deactivate" && (
                                <span className="deactivate-report">Deactivate</span>
                            )}

                            <span>{`${record.adminFirstName} ${record.adminLastName}`}</span>
                        </>
                    )}
                />

                <Column key="client-id" title="CLIENT ID" dataIndex="_id"
                    render={(_id) => (
                        <Tooltip title={_id}>
                            {`${_id.slice(0, 10)}${_id.length > 10 ? "..." : ""}`}
                        </Tooltip>
                    )}
                />

                <Column key="state" title='State'
                    render={(constituency, record, index) => (
                        <span
                            style={{ textTransform: "capitalize" }}
                        >{`${record?.constituency[0]?.constituencyState.toLowerCase()}`}</span>
                    )}
                />

                <Column key="subscription" title="SUBSCRIPTION" dataIndex="planType"
                    render={(text, record) =>
                        record.planType.map((plan) => plan.name).join(", ")
                    }
                />

                <Column title="CONSTITUENCY" dataIndex="constituencyName"
                    render={(constituency, record, index) => (
                        <span>
                            {`${record?.constituency[0]?.constituencyName} ${record.constituency?.length > 1 ? ` +${record.constituency.length - 1}` : ""}`}
                        </span>
                    )}
                />

                <Column key="start_date" title="START DATE" dataIndex="date" sortDirections={["ascend", "descend"]} className="custom-sorter w-40"
                    render={(text, record) => (
                        <span>
                            {new Date(record.validityStart).toLocaleDateString("en-GB")}{" "}
                        </span>
                    )}
                    sorter={(a, b) => new Date(a.validityStart) - new Date(b.validityStart)}
                />

                <Column key="end_date" title="END DATE" dataIndex="date" sortDirections={["ascend", "descend"]} className="custom-sorter"
                    render={(text, record) => (
                        <span>
                            {new Date(record.validityEnd).toLocaleDateString("en-GB")}
                        </span>
                    )}
                    sorter={(a, b) => new Date(a.validityStart) - new Date(b.validityStart)}
                />

                <Column title="ACTION" key="action"
                    render={(text, record, index) => (
                        <Space className='select-option' size="small" style={{}}>
                            <div className="dropdown" onMouseEnter={() => handleMouseEnter(index)} 
                            onMouseLeave={handleMouseLeave}
                            >
                                <span onClick={() => handleClick(index)}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="34" height="30" viewBox="0 0 34 30" fill="none">
                                        <circle cx="17.3889" cy="9.38845" r="1.38889" transform="rotate(-90 17.3889 9.38845)"  />
                                        <circle cx="17.3889" cy="15.4998" r="1.38889" transform="rotate(-90 17.3889 15.4998)"  />
                                        <circle cx="17.3889" cy="21.6111" r="1.38889" transform="rotate(-90 17.3889 21.6111)"  />
                                    </svg>
                                </span>
                                {isDropdownOpen(index) && (
                                    <div className="dropdown-content">
                                        <div onClick={() => handleEdit(record, 'status')}>
                                            {record.status === 'active' ? 'Deactivate' : 'Activate'} Client
                                        </div>
                                        {record.status === 'active' &&
                                            <Link to={`/clients-info/${record._id}`}>
                                                <div onClick={() => handleMouseLeave()}>Client Details</div>
                                            </Link>
                                        }
                                        {record.status === 'active' &&
                                            <div onClick={() => handleEdit(record, 'edit')}>Edit</div>
                                        }


                                        <div onClick={() => showModalDelete(record)}>Delete</div>
                                    </div>
                                )}
                            </div>
                        </Space>
                    )}
                />
            </Table>

            {clientContext.isStatusModal &&
                <DeactivateClient isStatusModal={clientContext.isStatusModal} editData={clientContext.editData} />
            }

            {isDeleteModal && (
                <DeleteModal
                    textheading="Delete Client"
                    deleteTitle="client"
                    deleteBtn="Delete Client"
                    handleDelete={() => handleDelete(selectedRecord)}
                    isDeleteModal={isDeleteModal}
                    showModalDelete={() => setDeleteModal(false)}
                    action='delete'
                />
            )}
        </div>
    )
};

export default ClientDataTable


