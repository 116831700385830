import React, { useState, useRef, useEffect, useCallback } from "react";
import {
  GoogleMap,
  useLoadScript,
  InfoWindow,
  Polygon
} from "@react-google-maps/api";
import useConstituencyByState from "./useConstituencyByState";
import { mapStyles } from "../../../utils/mapStyles";
import {
  lib,
  indiaCenter,
  initialZoom,
  indiaBounds
} from "../../../utils/mapConstants";
import AssemblyDataList from "../Assembly/AssemblyDataList";
import ParliamentaryDataList from "../Parliamentary/ParliamentaryDataList";
import { toast, ToastContainer } from "react-toastify";
import { isEmptyArray } from "formik";

function convertToFirstFormat(coordinates) {
  const result = coordinates.map((point) => {
    if (Array.isArray(point[0])) {
      // Handle the second format
      return [point[0][0], point[0][1]];
    } else {
      // Already in the first format
      return [point[0], point[1]];
    }
  });

  return result;
}

const MapBox = (props) => {
  const [polygonPaths, setPolygonPaths] = useState([]);
  const [activePolygon, setActivePolygon] = useState(null);

  const DataList =
    props.tab === "assembly" ? AssemblyDataList : ParliamentaryDataList;

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries: lib
  });

  const mapRef = useRef(null);

  const onLoad = useCallback((map) => {
    mapRef.current = map;
  }, []);

  const handlePolygonClick = useCallback((index) => {
    props.tab === "assembly"
      ? setActivePolygon((prevIndex) => (index === prevIndex ? null : index))
      : setActivePolygon(index);
  }, []);

  useEffect(() => {
    if (isLoaded) {
      if (isEmptyArray(props?.fetchData)) {
        toast.error("data not available");
      }

      if (props?.fetchData[0]?.boundaries?.type === "FeatureCollection") {
        const featureGeoJson = props?.fetchData[0]?.boundaries?.features.map(
          (feature) => {
            console.log("feature", feature);
            let featureCoordinates = [];
            if (feature.geometry?.type === "MultiPolygon") {
              featureCoordinates = feature.geometry?.coordinates.flat(2);
            } else if (feature.geometry?.type === "Polygon") {
              featureCoordinates = feature.geometry?.coordinates[0];
            }

            return {
              constituencyId: props?.fetchData[0]?._id || "",
              coordinate: featureCoordinates
                ? featureCoordinates.map((coord) => ({
                    lat: coord[1],
                    lng: coord[0]
                  }))
                : [],
              name: props?.fetchData[0]?.name || "",
              state: props?.fetchData[0]?.state,
              district: props?.fetchData[0]?.district
            };
          }
        );
        setPolygonPaths(featureGeoJson);
      } else {
        const geoJSON = (
          Array.isArray(props?.fetchData) ? props?.fetchData : []
        ).map((item) => {
          let coordinates = [];
          if (props.tab === "parliamentary") {
            const boundaries = item?.boundaries || [];
            let parliamentaryCoordinates = [];

            if (Array.isArray(boundaries)) {
              parliamentaryCoordinates = boundaries.map((boundary) => {
                if (boundary?.type === "Polygon") {
                  return boundary?.coordinates[0];
                } else if (boundary?.type === "MultiPolygon") {
                  return boundary?.coordinates.flat(2);
                }
              });
            } else {
              console.error("Boundaries is not an array:", boundaries);
            }

            // coordinates = parliamentaryCoordinates.reduce(
            //   (accumulator, currentValue) => {
            //     accumulator.push(...currentValue);
            //     return accumulator;
            //   },
            //   []
            // );

            const output = parliamentaryCoordinates.map((itm) => {
              return {
                constituencyId: item?._id || "",
                coordinate: itm
                  ? itm.map((coord) => ({
                      lat: coord[1],
                      lng: coord[0]
                    }))
                  : [],
                name: item?.name,
                state: item?.state,
                district: item?.district
              };
            });
            return output;
          } else if (props.tab === "assembly") {
            const boundaries = item?.boundaries;
            let coordinates;

            if (boundaries?.type === "Polygon") {
              coordinates = boundaries?.coordinates[0];
            } else if (boundaries?.type === "MultiPolygon") {
              coordinates = boundaries?.coordinates.flat(2);
            } else if (boundaries?.type === "FeatureCollection") {
              coordinates = boundaries?.features.flatMap((feature) => {
                if (feature.geometry?.type === "MultiPolygon") {
                  return feature.geometry?.coordinates.flat(2);
                } else if (feature.geometry?.type === "Polygon") {
                  return feature.geometry?.coordinates[0];
                }
                return [];
              });
              console.log("coordinates", coordinates);
            }

            return {
              constituencyId: item?._id || "",
              coordinate: coordinates
                ? coordinates.map((coord) => ({
                    lat: coord[1],
                    lng: coord[0]
                  }))
                : [],
              name: item?.name,
              state: item?.state,
              district: item?.district
            };
          }
        });
        console.log("geoJSON", geoJSON);
        const dataToUse =
          props.tab === "parliamentary"
            ? geoJSON.reduce((accumulator, currentValue) => {
                accumulator.push(...currentValue);
                return accumulator;
              }, [])
            : geoJSON;
        setPolygonPaths(dataToUse);
      }
    }
  }, [props.fetchData, isLoaded, props.tab]);

  useEffect(() => {
    if (
      polygonPaths &&
      Array.isArray(polygonPaths) &&
      polygonPaths.length > 0
    ) {
      const bounds = new window.google.maps.LatLngBounds();
      polygonPaths.forEach((polygon) => {
        polygon?.coordinate.forEach((item) => {
          bounds.extend(new window.google.maps.LatLng(item?.lat, item?.lng));
        });
      });
      const polygonCenter = bounds.getCenter();
      mapRef.current.fitBounds(bounds);
      mapRef.current.setCenter(polygonCenter);
    }
  }, [polygonPaths]);

  useEffect(() => {
    // Cleanup function
    return () => {
      // Reset activePolygon to null when the component is unmounted or when the polygon data changes
      setActivePolygon(null);
    };
  }, [props.fetchData]);

  const renderMap = () => {
    if (loadError) {
      return <div className='loader'>Error loading the map</div>;
    }

    if (isLoaded) {
      return (
        <>
          <ToastContainer></ToastContainer>
          <GoogleMap
            google={props.google}
            mapContainerClassName='map-container1'
            options={{
              styles: mapStyles,
              restriction: {
                latLngBounds: indiaBounds,
                strictBounds: true
              }
            }}
            onLoad={onLoad}
            ref={mapRef}
          >
            {(polygonPaths || []).map((polygon, index) => {
              const bounds = new window.google.maps.LatLngBounds();
              polygon?.coordinate.forEach((point) => {
                const lat = Number(point?.lat);
                const lng = Number(point?.lng);

                if (!isNaN(lat) && !isNaN(lng)) {
                  bounds.extend({ lat, lng });
                } else {
                  console.error("Invalid coordinateP:", point?.lat);
                }
              });
              const center = bounds.getCenter();

              return (
                <React.Fragment key={index}>
                  <Polygon
                    paths={polygon?.coordinate
                      ?.map((point) => ({
                        lat: Number(point?.lat),
                        lng: Number(point?.lng)
                      }))
                      .filter(
                        (point) => !isNaN(point.lat) && !isNaN(point.lng)
                      )}
                    options={{
                      // strokeColor: "black",
                      // strokeOpacity: 0.1,
                      // strokeWeight: 1,
                      // fillColor: "#663399",
                      // fillOpacity: 0.5
                      strokeColor: "#810FCB",
                      strokeOpacity: 1.0,
                      strokeWeight: 3.0,
                      fillColor: "#810FCB",
                      fillOpacity: 0.5
                    }}
                    onMouseOver={() => handlePolygonClick(index)}
                  />
                  {activePolygon === index && (
                    <InfoWindow
                      position={center}
                      onCloseClick={() => handlePolygonClick(null)}
                    >
                      <DataList
                        polygon={polygon}
                        year={props.year}
                        polygonPaths={polygonPaths}
                        tab={props.tab}
                      >
                        {" "}
                      </DataList>
                    </InfoWindow>
                  )}
                </React.Fragment>
              );
            })}
          </GoogleMap>
        </>
      );
    } else {
      return <div className='loader'>Loading...</div>;
    }
  };

  return <div className='map-container'>{isLoaded ? renderMap() : null}</div>;
};

export default MapBox;
