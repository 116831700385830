import React from "react";
import { Form, Row, Col, Select, Button, Popover } from "antd";
import statePCMapping from "../../../assets/data/state_pc_mapping.json";

const ParliamentarySearch = ({
  form,
  formValue,
  stateConstituencyOptions,
  districtOptions,
  handleChange,
  constituencyByState,
  electionYears,
  activeTab,
  setYear,
  handleReset,
  onFinish
}) => {
  const pcOptions = formValue?.state
    ? (statePCMapping[formValue?.state.toUpperCase()] || []).map((pc) => ({
        value: pc,
        label: pc
      }))
    : [];
  const { OptGroup, Option } = Select;
  const renderContent = () => (
    <Form
      form={form}
      onFinish={onFinish}
      initialValues={formValue}
      id='state-district-form'
      className='constituency-search'
    >
      <Row className='flex'>
        <Col span={10} offset={1}>
          <label>Select State</label>
          <Form.Item name='state'>
            <Select
              name='state'
              showSearch
              placeholder={"Andra Pradesh"}
              onChange={(value) => handleChange(value, "state")}
              options={stateConstituencyOptions}
              style={{ width: "150px" }}
            />
          </Form.Item>
        </Col>
        <Col span={10} offset={1} className='ml-5'>
          <label>Parliamentary </label>
          <Form.Item>
            <Select
              name='pcName'
              id='pcName'
              showSearch
              placeholder='Select Constituency'
              optionFilterProp='value'
              value={formValue?.pcName}
              filterOption={(input, option) =>
                option?.children?.toLowerCase().indexOf(input.toLowerCase()) >=
                0
              }
              onChange={(value) => handleChange(value, "pcName")}
              disabled={!formValue?.state || !pcOptions.length}
              options={pcOptions}
              style={{ width: "150px" }}
            ></Select>
          </Form.Item>
        </Col>
      </Row>
      <Row className='flex'>
        <Col span={10} offset={1} className=''>
          <label>Select Year</label>
          <Form.Item>
            <Select
              placeholder={"Year"}
              onChange={(value) => {
                setYear(value);
                handleChange(value, "year");
              }}
              disabled={!formValue?.state}
              style={{ width: "150px" }}
              value={formValue?.year}
            >
              <>
                <Select.Option value={2014}>2014</Select.Option>
                <Select.Option value={2019}>2019</Select.Option>
                <Select.Option value={2023}>2023</Select.Option>
              </>
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row className='flex justify-end'>
        <Button
          key='reset-form'
          onClick={handleReset}
          className='reset-button mb-3 mr-4'
          size='small'
          style={{
            width: "auto",
            color: "var(--dark-color)",
            background: "var(--input-bg)"
          }}
        >
          Reset
        </Button>
        <Button
          key='add-constituency'
          form='state-district-form'
          type='submit'
          size='small'
          onClick={onFinish}
          className='search-by mb-3'
          style={{ width: "auto", background: "#004877", color: "white" }}
        >
          Search
        </Button>
      </Row>
    </Form>
  );

  return (
    <Popover content={renderContent()} trigger='click' placement='bottomLeft'>
      <Button
        key='add-constituency'
        form='constituency-form'
        type='submit'
        className='search-by mt-5 mr-8'
        style={{ width: "auto", background: "#004877", color: "white" }}
      >
        Search By
      </Button>
    </Popover>
  );
};

export default ParliamentarySearch;
