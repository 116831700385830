import React, { useContext, useState } from "react";
import { Modal, Select, Row, Col, Radio, Form, Upload, Input } from "antd";

import { useQuery } from "react-query";
import stateDistrictMapping from "../../assets/data/Indian_district_State.json";

import ConstituencyContext from "../../context/constituencyContext";
import TextArea from "antd/es/input/TextArea";
import uploadCloud from "../../assets/svg/uploadCloud.svg";
import { FileFilled } from "@ant-design/icons";
import { AiOutlineClose } from "react-icons/ai";
import useAxiosInstance from "../../lib/useAxiosInstance";
import { ToastContainer, toast } from "react-toastify";
const { OptGroup, Option } = Select;

const AddConstituency = ({ isOpen, isExportMode }) => {
  const [form] = Form.useForm();
  const axiosInstance = useAxiosInstance();
  const constituencyContext = useContext(ConstituencyContext);

  const [radioValue, setRadioValue] = useState(1);
  const [fileList, setFileList] = useState([]);

  const years = [];
  const currentYear = new Date().getFullYear();
  for (let year = 1951; year <= currentYear; year++) {
    years.push(year);
  }

  const handleRadioChange = (e) => {
    setRadioValue(e.target.value);
  };

  const closeModal = () => {
    constituencyContext.updateOpenModal(false);
    constituencyContext.updateAddMode(true);
    form.resetFields();
    handleResetFile();
  };

  const [state, setState] = useState();
  const [district, setDistrict] = useState();
  const [inputValues, setInputValues] = useState({
    state: null,
    district: null,
    constituency: null,
    established: null,
    villageCount: "",
    pollingStation: "",
    description: "",
    boundaries: []
  });
  const handleResetFile = () => {
    setInputValues((prev) => ({ ...prev, boundaries: [] }));
  };

  // Define state and district options based on the stateDistrictMapping
  const stateConstituencyOptions = Object.keys(stateDistrictMapping).map(
    (state) => ({
      value: state,
      label: state
    })
  );
  const districtOptions = inputValues.state
    ? stateDistrictMapping[inputValues.state].map((district) => ({
        value: district,
        label: district
      }))
    : [];

  //  Fetch constituencyByState List-->
  const fetchConstituencyByState = async (state, district) => {
    if (state === undefined || district === undefined) {
      return Promise.resolve({ data: [] });
    }

    // If both state and district are defined, make the API call
    return axiosInstance.get(
      `api/constituency//getAllConstituency?state=${state}&district=${district}`
    );
  };

  const constituencyByState = useQuery(
    ["client-constituency-ByState", district, state],
    () => fetchConstituencyByState(inputValues.state, inputValues.district),
    {
      refetchOnWindowFocus: false
    },
    { retry: 1 }
  );

  const handleChange = (value, name) => {
    setInputValues({ ...inputValues, [name]: value });
    if (name === "district") {
      setDistrict(value);
    }
    if (name === "state") {
      setState(value);
    }
  };

  const handleGeoFileUpload = async (data) => {
    try {
      const response = await axiosInstance.post(
        "/api/constituency/createConstituency",
        data,
        {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        }
      );
      toast.success("Geo JSON Uploaded Successfully");
      closeModal();
      return response?.data?.data; // You might need to adjust this based on your API response structure
    } catch (error) {
      console.error("CSV file upload failed:", error.message);
      return null;
    }
  };
  const handleSubmitForm = (e) => {
    const data = {
      state: inputValues?.state,
      boundaries: inputValues?.boundaries[0]?.originFileObj,
      establishedYear: inputValues?.established,
      noOfPeople: 500,
      description: inputValues?.description,
      name: inputValues?.constituency,
      district: inputValues?.district,
      tehsil: "",
      type: "state"
    };
    handleGeoFileUpload(data);
  };

  return (
    <>
      {isOpen && (
        <Modal
          className='add-user-modal'
          open={isOpen}
          onCancel={closeModal}
          width={1040}
          maskClosable={false}
          footer={[
            <button
              key='add-constituency'
              form='constituency-form'
              type='submit'
              className='add-user-btn'
              style={{ width: "auto" }}
            >
              Add New Constituency
            </button>
          ]}
        >
          <ToastContainer></ToastContainer>
          <Form
            form={form}
            id='constituency-form'
            onFinish={handleSubmitForm}
            className='modal-body-container'
          >
            <div className='user-info-modal'>
              <div className='modal-title'>
                <h3>Add New Constituency</h3>
              </div>
            </div>
            <div className='modal-wrapper-body constituency-form'>
              <div className='model-wrapper'>
                <div className='modal-wrapper-content'>
                  <h4>Constituency Information</h4>
                  <Row className='add-user-modal-radio'>
                    <Col>
                      <label className='opacity-60 !important'>Type</label>
                      <Form.Item>
                        <Radio.Group
                          className='radio'
                          value={radioValue}
                          onChange={handleRadioChange}
                        >
                          <Radio value={1}>Assembly</Radio>
                          <Radio value={2} disabled>
                            Parliamentary
                          </Radio>
                        </Radio.Group>
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row className='add-modal-row mt-5 mb-2'>
                    <Col span={8}>
                      <label>
                        Select State<span className='required-field'>*</span>
                      </label>
                      <Form.Item
                        name='state'
                        rules={[
                          {
                            required: true,
                            message: "Please Select State"
                          }
                        ]}
                      >
                        <Select
                          name='state'
                          onChange={(value) => handleChange(value, "state")}
                          options={stateConstituencyOptions}
                        />
                      </Form.Item>
                    </Col>

                    <Col span={8} offset={2}>
                      <label>
                        Select District<span className='required-field'>*</span>
                      </label>
                      <Form.Item
                        name='district'
                        rules={[
                          {
                            required: true,
                            message: "Please Select District"
                          }
                        ]}
                      >
                        <Select
                          onChange={(value) => handleChange(value, "district")}
                          options={districtOptions}
                          disabled={!inputValues.state}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row className='add-modal-row mb-2'>
                    <Col span={8}>
                      <label>
                        Constituency<span className='required-field'>*</span>
                      </label>
                      <Form.Item
                        name='Constituency'
                        rules={[
                          {
                            required: true,
                            message: "Constituency is required"
                          }
                        ]}
                      >
                        <Input
                          name='constituency'
                          id='constituency'
                          // optionFilterProp='value'
                          // filterOption={(input, option) =>
                          //   option?.children
                          //     ?.toLowerCase()
                          //     .indexOf(input.toLowerCase()) >= 0
                          // }
                          // onChange={(value, option) => {
                          //   const selectedConstituencyName = option?.children;
                          //   handleChange(
                          //     selectedConstituencyName,
                          //     "constituency"
                          //   );
                          // }}
                          onChange={(value) =>
                            handleChange(value?.target?.value, "constituency")
                          }
                          // disabled={
                          //   !inputValues.state ||
                          //   !inputValues.district ||
                          //   !constituencyByState?.data?.data?.data.length
                          // }
                          required
                        >
                          {/* <OptGroup>
                          {constituencyByState?.data?.data?.data
                            ?.filter(
                              (constituency) => constituency.name !== null
                            ) // Filter out null values
                            .sort((a, b) => a.name.localeCompare(b.name)) // Sort the data alphabetically
                            .map((constituency) => (
                              <Option
                                key={constituency._id}
                                value={constituency?.name}
                              >
                                {constituency.name}
                              </Option>
                            ))}
                        </OptGroup> */}
                        </Input>
                      </Form.Item>
                    </Col>
                    <Col span={8} offset={2}>
                      <label>
                        Established Year
                        <span className='required-field'>*</span>
                      </label>
                      <Form.Item
                        name='established'
                        rules={[
                          {
                            required: true,
                            message: "Established Year is Required"
                          }
                        ]}
                      >
                        <Select
                          name='established'
                          id='established'
                          onChange={(value) =>
                            handleChange(value, "established")
                          }
                          required
                        >
                          {years.map((year) => (
                            <Option key={year} value={year}>
                              {year}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                  {/* <Row className='add-modal-row mb-2'>
                  <Col span={8}>
                    <label htmlFor=''>
                      No. of Villages<span className='required-field'>*</span>
                    </label>
                    <Form.Item
                      name='villageCount'
                      rules={[
                        {
                          required: true,
                          message: "Please enter the number of villages"
                        },
                        {
                          validator: (_, value) => {
                            if (value >= 0) {
                              return Promise.resolve();
                            }
                            return Promise.reject(
                              " should be greater than or equal to 0"
                            );
                          }
                        }
                      ]}
                    >
                      <Input
                        name='villageCount'
                        placeholder='Enter No of Village'
                        type='number'
                        size='large'
                        onChange={(value) =>
                          handleChange(value?.target?.value, "villageCount")
                        }
                        required
                      />
                    </Form.Item>
                  </Col>

                  <Col span={8} offset={2}>
                    <label htmlFor=''>
                      No. of Polling Station
                      <span className='required-field'>*</span>
                    </label>
                    <Form.Item
                      name='pollingStation'
                      rules={[
                        {
                          required: true,
                          message: "Number of Polling Station is required"
                        },
                        {
                          validator: (_, value) => {
                            if (value >= 0) {
                              return Promise.resolve();
                            }
                            return Promise.reject(
                              " should be greater than or equal to 0"
                            );
                          }
                        }
                      ]}
                    >
                      <Input
                        name='pollingStation'
                        size='large'
                        type='number'
                        placeholder='Enter No of Polling Station'
                        onChange={(value) =>
                          handleChange(value?.target?.value, "pollingStation")
                        }
                      />
                    </Form.Item>
                  </Col>
                </Row> */}
                  <Row className='add-modal-row mb-2'>
                    <Col span={18}>
                      <label>
                        Description<span className='required-field'>*</span>
                      </label>
                      <Form.Item
                        name='description'
                        rules={[
                          {
                            required: true,
                            message: "Please enter the Description"
                          }
                        ]}
                      >
                        <TextArea
                          rows={2}
                          name='description'
                          onChange={(value) =>
                            handleChange(value?.target?.value, "description")
                          }
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row className='add-modal-row mb-2'>
                    <Col span={22}>
                      <h3>
                        Constituency Shape File{" "}
                        <span className='font-normal'>
                          (Geojson)<span className='required-field'>*</span>
                        </span>
                      </h3>
                      <Form.Item
                        name='boundaries'
                        rules={[
                          {
                            required: true,
                            message: "Please upload the shape file"
                          }
                        ]}
                      >
                        <div className='constituency-upload-file'>
                          <div className='mt-3'>
                            <Upload.Dragger
                              key='select-file'
                              name='boundaries'
                              beforeUpload={() => false}
                              fileList={fileList}
                              onChange={(info) => {
                                const { file, fileList } = info;
                                if (file.status === "done") {
                                  setFileList([file]);
                                } else {
                                  setFileList([]);
                                }
                                handleChange(fileList, "boundaries");
                              }}
                              className='custom-file-upload'
                              accept='.json'
                            >
                              {inputValues?.boundaries.length > 0 ? (
                                <div className='fileUpload'>
                                  <div className='text-right'>
                                    <button
                                      onClick={handleResetFile}
                                      className='reset-button font-bold'
                                    >
                                      <AiOutlineClose />
                                    </button>
                                  </div>
                                  <div className='flex file-icon'>
                                    <FileFilled />
                                    <p>{inputValues?.boundaries[0]?.name}</p>
                                  </div>
                                </div>
                              ) : (
                                <div>
                                  <img
                                    src={uploadCloud}
                                    alt=''
                                    className='m-auto'
                                  />
                                  <p className='ant-upload-text'>
                                    Select a file or drag and drop
                                  </p>
                                  <p className='ant-upload-text'>
                                    JPG, PNG, or PDF, file size no more than
                                    10MB
                                  </p>
                                  <span className='select-file'>
                                    Select File
                                  </span>
                                  <div className='fileUpload'></div>
                                </div>
                              )}
                            </Upload.Dragger>
                          </div>
                        </div>
                      </Form.Item>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          </Form>
        </Modal>
      )}
    </>
  );
};

export default AddConstituency;
