import React, { useState } from "react";
import { Input, Select, Row, Col, Radio } from "antd";
import ErrorMessage from "../../Forms/ErrorMessage";
import { useQuery, useQueryClient } from "react-query";
import stateDistrictMapping from "../../../assets/data/Indian_district_State.json";
import useAxiosInstance from "../../../lib/useAxiosInstance";
const { Option } = Select;

const ClientInfoForm = ({
  formik1,
  isAddMode,
  constituencyStates,
  setConstituencyStates,
  constituencyDistricts,
  setConstituencyDistricts
}) => {
  const axiosInstance = useAxiosInstance();
  const queryClient = useQueryClient();
  const planListQuery = queryClient.getQueryData("plan-list");
  const [radioValue, setRadioValue] = useState(1);

  // subscription date
  const handleDateChange = (event) => {
    const formattedDate = event.target.value;
    formik1.setFieldValue("validityStart", formattedDate);
  };
  const handleRadioChange = (e) => {
    setRadioValue(e.target.value);
  };
  const fetchConstituencyByState = async (state, district) => {
    return axiosInstance.get(
      `api/constituency/getAllConstituency?state=${state}&district=${district}`
    );
  };
  const constituencyByState = useQuery(
    [
      "addClient-constituency-ByState",
      constituencyStates,
      constituencyDistricts
    ],
    () =>
      fetchConstituencyByState(
        formik1.values.constituencyState,
        formik1.values.constituencyDistrict
      ),
    {
      enabled:
        formik1.values.constituencyState !== "" &&
        formik1.values.constituencyDistrict !== "",
      refetchOnWindowFocus: false
    }
  );
  // Define state and district options based on the stateDistrictMapping
  const stateConstituencyOptions = Object.keys(stateDistrictMapping).map(
    (constituencyState) => ({
      value: constituencyState,
      label: constituencyState
    })
  );

  // const districtOptions = formik1.values.constituencyState
  //     ? stateDistrictMapping[formik1.values.constituencyState.toUpperCase()]?.map(
  //         (constituencyDistrict) => ({
  //             value: constituencyDistrict,
  //             label: constituencyDistrict
  //         })
  //     )
  //     : [];

  const selectedStateTitleCase = formik1.values.constituencyState;
    // .toLowerCase()
    // .replace(/\b\w/g, (match) => match.toUpperCase());

  const districtsForSelectedState =
    stateDistrictMapping[selectedStateTitleCase];

  // Updated districtOptions based on the converted selected state
  const districtOptions = districtsForSelectedState
    ? districtsForSelectedState?.map((constituencyDistrict) => ({
        value: constituencyDistrict,
        label: constituencyDistrict
      }))
    : [];

  const handleChange = (value, name) => {
    formik1.setFieldValue(name, value);
    if (name === "constituencyDistrict") {
      formik1.setFieldValue("constituencyName", "");
      setConstituencyDistricts(value);
      fetchConstituencyByState(value);
    }
    if (name === "constituencyState") {
      formik1.setFieldValue("constituencyName", "");
      formik1.setFieldValue("constituencyDistrict", "");
      setConstituencyStates(value);
      fetchConstituencyByState(value);
    }
  };

  return (
    <>
      <form onSubmit={formik1.handleSubmit}>
        <div className='client-info modal-body-container'>
          <div className='modal-title'>
            <h3>{isAddMode ? "Add New Client" : "Edit Client"}</h3>
          </div>

          <div className='modal-wrapper-body'>
            <div className='modal-wrapper-content'>
              <h4>Client Information</h4>

              <Row className='add-modal-row'>
                <Col span={8} className='mr-6'>
                  <label htmlFor=''>First Name</label>
                  <Input.Group compact className='client-first-name'>
                    <Select
                      placeholder='Mr.'
                      style={{ width: "25%" }}
                      onChange={(value) =>
                        formik1.setFieldValue("prefix", value)
                      }
                      value={formik1.values.prefix || undefined}
                    >
                      <Option value='Mr.'>Mr.</Option>
                      <Option value='Mrs.'>Mrs.</Option>
                      <Option value='Ms.'>Ms.</Option>
                    </Select>
                    <Input
                      type='text'
                      placeholder='John'
                      name='adminFirstName'
                      onChange={formik1.handleChange}
                      value={formik1.values.adminFirstName}
                    />
                  </Input.Group>
                  <ErrorMessage
                    hasError={Boolean(
                      (formik1.errors.adminFirstName &&
                        formik1.touched.adminFirstName) ||
                        (formik1.errors.prefix && formik1.touched.prefix)
                    )}
                    message={
                      formik1.errors.adminFirstName || formik1.errors.prefix
                    }
                  />
                </Col>

                <Col span={8}>
                  <label htmlFor=''>Last Name</label>
                  <Input
                    type='text'
                    placeholder='Smith'
                    name='adminLastName'
                    onChange={formik1.handleChange}
                    value={formik1.values.adminLastName}
                  />
                  <ErrorMessage
                    hasError={Boolean(
                      formik1.errors.adminLastName &&
                        formik1.touched.adminLastName
                    )}
                    message={formik1.errors.adminLastName}
                  />
                </Col>
              </Row>

              <Row className='add-modal-row'>
                <Col span={8} className='mr-6'>
                  <label htmlFor=''>Email</label>
                  <Input
                    type='text'
                    placeholder='john.smith@abc.com'
                    name='adminEmail'
                    onChange={formik1.handleChange}
                    value={formik1.values.adminEmail}
                  />
                  <ErrorMessage
                    hasError={Boolean(
                      formik1.errors.adminEmail && formik1.touched.adminEmail
                    )}
                    message={formik1.errors.adminEmail}
                  />
                </Col>

                <Col span={8} className='phone-input'>
                  <label htmlFor=''>Phone</label>
                  <Input
                    type='number'
                    placeholder='1234567890'
                    addonBefore='+91'
                    name='adminContact'
                    onChange={formik1.handleChange}
                    value={formik1.values.adminContact}
                  />
                  <ErrorMessage
                    hasError={Boolean(
                      formik1.errors.adminContact &&
                        formik1.touched.adminContact
                    )}
                    message={formik1.errors.adminContact}
                  />
                </Col>
              </Row>

              <Row className='add-modal-row'>
                <Col span={8} className='mr-6 pb-1 planType'>
                  <label>Select Plan</label>
                  <Select
                    id='subscription'
                    placeholder='Please Select'
                    style={{ width: "100%" }}
                    size='large'
                    name='planType'
                    value={formik1.values.planType.map((item) => item.name)}
                    onChange={(value) => {
                      const formattedValue = value.map((name, index) => ({
                        _id: planListQuery?.data?.data[index]._id,
                        name: name
                      }));
                      formik1.setFieldValue("planType", formattedValue);
                    }}
                    showSearch={false}
                    mode='multiple'
                    getPopupContainer={(triggerNode) => triggerNode.parentNode}
                  >
                    {planListQuery?.data?.data?.map((plan) => (
                      <Select.Option key={plan._id} value={plan.name}>
                        {plan.name}
                      </Select.Option>
                    ))}
                  </Select>
                  <ErrorMessage
                    hasError={Boolean(
                      formik1.errors.planType && formik1.touched.planType
                    )}
                    message={formik1.errors.planType}
                  />
                </Col>

                <Col span={8} className='planDuration'>
                  <label>Plan Duration</label>
                  <Select
                    style={{ width: "100%", height: "48px" }}
                    placeholder='Please Select'
                    name='planDuration'
                    onChange={(value) =>
                      formik1.setFieldValue("planDuration", value)
                    }
                    value={formik1.values.planDuration}
                    getPopupContainer={(triggerNode) => triggerNode.parentNode}
                  >
                    <Select.Option value='quarterly'>Quarterly</Select.Option>
                    <Select.Option value='yearly'>Yearly</Select.Option>
                    <Select.Option value='half_yearly'>
                      Half-Yearly{" "}
                    </Select.Option>
                  </Select>
                  <ErrorMessage
                    hasError={Boolean(
                      formik1.errors.planDuration &&
                        formik1.touched.planDuration
                    )}
                    message={formik1.errors.planDuration}
                  />
                </Col>
              </Row>
              <Row className='add-modal-row'>
                <Col span={8} className='client-start-date mr-6'>
                  <label>Plan Start Date</label>
                  <Input
                    type='date'
                    placeholder='Select Date'
                    name='validityStart'
                    onChange={handleDateChange}
                    value={formik1.values.validityStart}
                    // min={new Date().toISOString().split('T')[0]}
                  />
                  <ErrorMessage
                    hasError={Boolean(
                      formik1.errors.validityStart &&
                        formik1.touched.validityStart
                    )}
                    message={formik1.errors.validityStart}
                  />
                </Col>

                <Col span={8} className='plan-amt'>
                  <label>Plan Amount</label>
                  <Input
                    placeholder='₹'
                    className='indent-3'
                    name='planAmount'
                    type='number'
                    value={formik1.values.planAmount}
                    onChange={formik1.handleChange}
                  />
                  {formik1.values.planAmount && (
                    <span className='absolute  left-3'>₹</span>
                  )}
                  <ErrorMessage
                    hasError={Boolean(
                      formik1.errors.planAmount && formik1.touched.planAmount
                    )}
                    message={formik1.errors.planAmount}
                  />
                </Col>
              </Row>

              <Row className='add-modal-row client-password'>
                <Col span={8} className='mr-6'>
                  <label>Password</label>
                  <Input.Password
                    type='password'
                    name='password'
                    id='password'
                    onChange={formik1.handleChange}
                    value={formik1.values.password}
                  />

                  <ErrorMessage
                    hasError={Boolean(
                      formik1.errors.password && formik1.touched.password
                    )}
                    message={formik1.errors.password}
                  />
                </Col>
                <Col span={8}>
                  <label>Confirm Password</label>
                  <Input.Password
                    type='password'
                    name='confirmpass'
                    value={formik1.values.confirmpass}
                    id='confirmpass'
                    onChange={formik1.handleChange}
                  />
                  <ErrorMessage
                    hasError={Boolean(
                      formik1.errors.confirmpass && formik1.touched.confirmpass
                    )}
                    message={formik1.errors.confirmpass}
                  />
                </Col>
              </Row>

              <h4>Constituency</h4>
              <Row className='add-user-modal-radio  mb-5'>
                <Col>
                  <label className='opacity-60 mb-2'>Type</label>
                  <Radio.Group
                    className='radio'
                    value={radioValue}
                    onChange={handleRadioChange}
                  >
                    <Radio value={1}>Assembly</Radio>
                    <Radio value={2} disabled>
                      Parliamentary
                    </Radio>
                  </Radio.Group>
                </Col>
              </Row>

              <Row className='add-user-modal-radio mb-5'>
                <Col span={8} className='mr-7'>
                  <label className='inline-block'>Select State</label>
                  <Select
                    className='capitalize'
                    id='constituencyState'
                    placeholder='Please Select'
                    size='large'
                    style={{ width: "100%", height: "60px" }}
                    name='constituencyState'
                    // onChange={formik1.handleChange}
                    value={formik1.values.constituencyState}
                    onChange={(value) =>
                      handleChange(value, "constituencyState")
                    }
                    options={stateConstituencyOptions}
                    getPopupContainer={(triggerNode) => triggerNode.parentNode}
                  />
                  <ErrorMessage
                    hasError={Boolean(
                      formik1.errors.constituencyState &&
                        formik1.touched.constituencyState
                    )}
                    message={formik1.errors.constituencyState}
                  />
                </Col>

                <Col span={8} className=''>
                  <label>District</label>
                  <Select
                    className='capitalize'
                    size='large'
                    style={{ width: "100%", height: "60px" }}
                    // showSearch
                    id='constituency_district'
                    placeholder='Please Select'
                    name='constituencyDistrict'
                    value={formik1.values.constituencyDistrict}
                    onChange={(value) =>
                      handleChange(value, "constituencyDistrict")
                    }
                    options={districtOptions}
                    disabled={!formik1.values.constituencyState}
                    getPopupContainer={(triggerNode) => triggerNode.parentNode}
                  />
                  <ErrorMessage
                    hasError={Boolean(
                      formik1.errors.constituencyDistrict &&
                        formik1.touched.constituencyDistrict
                    )}
                    message={formik1.errors.constituencyDistrict}
                  />
                </Col>
              </Row>

              <Row className='add-user-modal-radio mb-7'>
                <Col span={8}>
                  <label htmlFor=''>Select Home Constituency</label>
                  <Select
                    size='large'
                    style={{ width: "100%", height: "60px" }}
                    className='constituency'
                    placeholder='Select Constituency'
                    defaultValue='Select Constituency'
                    name='constituencyName'
                    value={
                      formik1.values.constituency.length > 0
                        ? formik1.values.constituencyName
                        : ""
                    }
                    showSearch
                    optionFilterProp='value'
                    filterOption={(input, option) =>
                      option?.children
                        ?.toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    onChange={(value) => {
                      const selectedConstituency =
                        constituencyByState.data.data.data.find(
                          (constituency) => constituency._id === value
                        );

                      formik1.setFieldValue("constituency", [
                        selectedConstituency
                          ? {
                              constituencyId: selectedConstituency._id,
                              constituencyName: selectedConstituency.name,
                              constituencyState: selectedConstituency.state,
                              constituencyDistrict:
                                selectedConstituency.district,
                              constituencyType: selectedConstituency.type
                            }
                          : {}
                      ]);

                      formik1.setFieldValue("constituencyName", value);
                    }}
                    disabled={
                      !formik1.values.constituencyState ||
                      !formik1.values.constituencyDistrict ||
                      !constituencyByState?.data?.data?.data.length
                    }
                    getPopupContainer={(triggerNode) => triggerNode.parentNode}
                  >
                    {constituencyByState?.data?.data?.data?.map(
                      (constituency) => (
                        <Select.Option
                          key={constituency._id}
                          value={constituency._id}
                        >
                          {constituency.name}
                        </Select.Option>
                      )
                    )}
                  </Select>
                  <ErrorMessage
                    hasError={Boolean(
                      formik1.errors.constituencyName &&
                        formik1.touched.constituencyName
                    )}
                    message={formik1.errors.constituencyName}
                  />
                </Col>
              </Row>
            </div>
          </div>

          <div className='flex justify-end client-modal-footer'>
            <button type='submit' className='ant-btn primary-btn mr-3'>
              Next
            </button>
          </div>
        </div>
      </form>
    </>
  );
};

export default ClientInfoForm;
